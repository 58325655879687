<template>
  <div id="userView">
    <p>个人中心</p>
    <br />
    <div class="bottom">
      <div class="left">
        <div class="img" v-if="userVO">
          <img
            v-if="userVO.userAvatar"
            v-bind:src="userVO.userAvatar"
            style="width: 250px"
          />
          <img
            v-else
            src="../../assets/userAvatar(Define).png"
            style="width: 250px"
          />
        </div>

        <div class="updateAvatar">
          <a-space direction="vertical" :style="{ width: '100%' }">
            <a-upload
              :custom-request="handleAvatarUpload"
              @before-upload="beforeUpload"
            >
              <template #upload-button>
                <a-space>
                  <a-button>更换头像</a-button>
                </a-space>
              </template>
            </a-upload>
          </a-space>
        </div>

        <div class="userRole" v-if="userVO">
          <a-tag
            v-if="userVO.userRole === 'admin'"
            color="orangered"
            bordered
            size="large"
            >管理员
          </a-tag>
          <a-tag
            v-if="userVO.userRole === 'user'"
            color="blue"
            bordered
            size="large"
            >普通用户
          </a-tag>
        </div>
      </div>

      <div class="right">
        <div class="form">
          <form v-if="userVO">
            <div class="form-group">
              <div class="label">账号：</div>
              <div id="userAccount">{{ userVO.userAccount }}</div>
            </div>

            <div class="form-group">
              <div class="label">用户名：</div>
              <input
                type="text"
                id="userName"
                v-model="userVO.userName"
                autocomplete="off"
              />
            </div>

            <div class="form-group">
              <div class="label">个人简介：</div>
              <textarea
                id="userProfile"
                v-model="userVO.userProfile"
              ></textarea>
            </div>
          </form>

          <button type="button" @click="userUpdate">更新信息</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, reactive, ref, withDefaults } from "vue";
import { useRouter } from "vue-router";
import message from "@arco-design/web-vue/es/message";
import store from "@/store";
import {
  UserControllerService,
  UserUpdateMyRequest,
  UserVO,
} from "../../../generated";
import { Modal } from "@arco-design/web-vue";

const router = useRouter();

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

const idRef = ref(props.id);

const userVO = ref<UserVO>();

const form = reactive({
  userAvatar: "",
  userName: "",
  userProfile: "",
} as UserUpdateMyRequest);

const loadData = async () => {
  // alert(props.id);
  const res = await UserControllerService.getUserVoByIdUsingGet(
    idRef.value as any
  );
  if (res.code === 0) {
    // alert(JSON.stringify(res.data));
    userVO.value = res.data;
    // alert(JSON.stringify(userVO.value));
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 页面加载时，请求数据
 */
onMounted(async () => {
  // 在页面加载时读取 localStorage 里的状态信息
  const loginUserString = localStorage.getItem("loginUser");
  // alert("loginUserString" + loginUserString);
  if (loginUserString) {
    const loginUser = JSON.parse(loginUserString);
    if (loginUser) {
      // 如果存在登录信息，更新到 Vuex 的状态中
      store.commit("updateUser", loginUser);
    }

    idRef.value = loginUser.id || "";
  }

  // 加载数据
  await loadData();
});

const userUpdate = async () => {
  // 更新 form 的值
  form.userAvatar = userVO.value?.userAvatar || "";
  form.userName = userVO.value?.userName || "";
  form.userProfile = userVO.value?.userProfile || "";
  // alert(JSON.stringify(form));

  if (!form.userAvatar) {
    message.error("用户头像不能为空");
    return;
  }

  if (!form.userName) {
    message.error("用户名不能为空");
    return;
  }

  const res = await UserControllerService.updateMyUserUsingPost(form);
  if (res.code === 0) {
    const loginUserString = localStorage.getItem("loginUser");
    if (loginUserString) {
      const loginUser = JSON.parse(loginUserString);

      // 更新 loginUser 的值
      loginUser.userAvatar = form.userAvatar;
      loginUser.userName = form.userName;
      loginUser.userProfile = form.userProfile;

      // 将更新后的 loginUser 存回 localStorage
      localStorage.setItem("loginUser", JSON.stringify(loginUser));

      message.success("更新成功");

      // 刷新页面
      window.location.reload();
    }
  } else {
    message.error("更新失败，" + res.message);
  }
};

/*
 * 处理头像上传
 */
const handleAvatarUpload = async (option: any) => {
  const { onProgress, onError, onSuccess, fileItem, name } = option;
  const file = fileItem.file; // 获取用户选择的文件
  if (!file) return;

  const formData = new FormData(); // 创建一个 FormData 对象来存储文件
  formData.append("file", file); // 将文件添加到 FormData 中，'avatar' 是后端接受文件的字段名
  // const res = await UserControllerService.updateUserAvatarUsingPost(file);

  const res = await UserControllerService.updateUserAvatarUsingPost(formData);
  // alert(JSON.stringify(res));
  if (res.code === 0) {
    const loginUserString = localStorage.getItem("loginUser");
    if (loginUserString) {
      const loginUser = JSON.parse(loginUserString);

      // 更新 loginUser 的值
      loginUser.userAvatar = res.data;

      // 将更新后的 loginUser 存回 localStorage
      localStorage.setItem("loginUser", JSON.stringify(loginUser));

      message.success("头像更新成功");
      // 刷新页面
      window.location.reload();
    } else {
      message.error("头像更新失败，" + res.message);
    }
  }
};

const beforeUpload = () => {
  return new Promise((resolve, reject) => {
    Modal.confirm({
      content: `确认是否更换头像`,
      onOk: () => resolve(true),
      onCancel: () => reject("cancel"),
    });
  });
};
</script>

<style scoped>
#userView {
  display: flex;
  flex-direction: column;
  width: 1000px;
  min-height: 400px;
  margin: 0 20px;
  padding: 30px 30px 100px;

  backdrop-filter: blur(10px); /* 添加模糊效果 */
  background-color: rgba(255, 255, 255, 0.3); /* 使用半透明的背景颜色 */
  border-radius: 10px;
}

#userView p {
  margin: 0 auto 30px;
  /*color: #fff;*/
  font-size: 38px;
  font-weight: bold;
  /*text-align: center;*/
}

#userView .bottom {
  display: flex;
}

#userView .bottom .left {
  flex: 3;
}

#userView .bottom .left .img {
  width: 250px;
  height: 250px; /* 设置图片容器的宽高 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  overflow: hidden; /* 超出容器的部分隐藏 */

  margin: 20px 5px 25px 70px;
  border-radius: 15px;
  background-color: #fff;
}

#userView .bottom .left .img img {
  width: 100%; /* 图片宽度填充容器 */
  height: 100%; /* 图片高度填充容器 */
  object-fit: cover; /* 等比例缩放并填充容器 */
}

#userView .bottom .left .updateAvatar {
  margin: 20px auto;
}

#userView .bottom .right {
  flex: 4;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  height: 350px;

  margin-right: 30px;
  padding: 20px 50px;
  font-size: 16px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8); /* 使用半透明的背景颜色 */
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#userView .right .form .form-group {
  display: flex;
  flex-direction: row;

  margin: 15px 7px;
}

#userView .right .form .form-group .label {
  flex: 1;
  padding-top: 15px;
}

#userView .right .form .form-group #userAccount,
#userView .right .form .form-group #userName,
#userView .right .form .form-group #userProfile {
  flex: 3;
  padding-left: 10px;
}

#userView .right .form .form-group #userAccount {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 10px;
}

#userView .right .form .form-group #userName {
  text-align: left;

  width: 80px;
  height: 40px;
  border-radius: 5px;
}

#userView .right .form-group #userProfile {
  width: 400px;
  height: 100px;
  border-radius: 5px;
  vertical-align: top;
  white-space: pre-wrap; /* 保留空白字符，并允许文本换行 */
  resize: none; /* 不允许用户调整宽度和高度 */
}

#userView .right button {
  position: relative;

  align-self: center;

  width: 100px;
  height: 40px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ccc; /* 设置边框 */
  font-size: 16px;
  font-weight: 500;
  color: #eee;
  background-color: #5cb85c;
  cursor: pointer;
}

#userView .right button:hover {
  background-color: #4cae4c;
}
</style>
