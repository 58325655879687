<template>
  <div id="manageQuestionView">
    <p>编辑题单</p>

    <!--    <div class="search">-->
    <!--      &lt;!&ndash;    搜索框&ndash;&gt;-->
    <!--      <a-form :model="searchParams" layout="inline">-->
    <!--        <a-form-item field="title" label="名称" style="min-width: 240px">-->
    <!--          <a-input v-model="searchParams.title" placeholder="请输入名称" />-->
    <!--        </a-form-item>-->
    <!--        <a-form-item>-->
    <!--          <a-button type="primary" @click="doSubmit()">查询</a-button>-->
    <!--        </a-form-item>-->
    <!--      </a-form>-->
    <!--    </div>-->

    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <template #title="{ record }">
        <div style="width: 150px">{{ record.title }}</div>
      </template>

      <template #content="{ record }">
        <div
          style="
            width: 500px;
            max-height: 60px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          "
        >
          {{ record.content }}
        </div>
      </template>

      <template #createTime="{ record }">
        <div style="width: 100px">
          {{ moment(record.createTime).format("YYYY-MM-DD") }}
        </div>
      </template>

      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate(record)"> 修改</a-button>
          <a-popconfirm
            content="确定删除该题单吗？"
            type="error"
            @ok="doDelete(record)"
          >
            <a-button status="danger">删除</a-button>
          </a-popconfirm>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionLesson,
  QuestionListRequest,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

import moment from "moment";

const show = ref(true);
const tableRef = ref();

const dataList = ref([]);
const total = ref(0);

const searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 8,
  current: 1,
});

const form = ref<QuestionListRequest>({
  questionList: [],
  pageSize: 8,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionLessonByPageUsingPost(
    form.value
  );
  // alert(JSON.stringify(res));
  if (res.code === 0) {
    // 获取 records
    const records = res.data.records;

    // 将每个记录中的 questionList 转换为 List<Long>
    records.forEach((record: any) => {
      // 将 JSON 字符串转换为数组
      const questionListArray = JSON.parse(record.questionList);
      // 将数组中的每个元素转换为数字
      const questionList = questionListArray.map(Number);
      // 将转换后的 questionList 设置回 record 对象
      record.questionList = questionList;
    });
    // alert(JSON.stringify(records));
    dataList.value = records;
    total.value = res.data.total;
  } else {
    if (res.message === "未登录") {
      // 删除本地缓存中的 loginUser 数据
      localStorage.removeItem("loginUser");
      // 用户没有登录，跳转到登录页面，并在 URL 中添加一个 redirect 参数
      message.info("加载失败，请先登录");
      // 获取当前页面的路径作为跳转路径
      const redirectPath = window.location.pathname;
      // alert(redirectPath);
      router.push({
        path: "/user/login",
        query: {
          redirect: redirectPath,
        },
      });
    } else {
      message.error("加载失败，" + res.message);
    }
  }
};

/**
 * 监听下面的变量，一旦发生改变，重新触发函数
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const columns = [
  {
    title: "id",
    dataIndex: "id",
  },
  {
    title: "标题",
    slotName: "title",
  },
  {
    title: "内容",
    slotName: "content",
  },
  {
    title: "创建时间",
    slotName: "createTime",
  },
  {
    title: "操作",
    slotName: "optional",
  },
];

/**
 * 分页
 * @param page
 */
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const doDelete = async (questionLesson: QuestionLesson) => {
  const res = await QuestionControllerService.deleteQuestionLessonUsingPost({
    id: questionLesson.id,
  });
  if (res.code === 0) {
    message.success("删除成功");
    loadData();
  } else {
    message.error("删除失败");
  }
};

const router = useRouter();

const doUpdate = (question: Question) => {
  router.push({
    path: "/update/question_lesson",
    query: {
      id: question.id,
    },
  });
};

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
</script>

<style scoped>
#manageQuestionView {
  margin: 0 20px;
  padding: 0 20px;
}

#manageQuestionView p {
  font-size: 38px;
  font-weight: bold;
  margin: 0 auto 30px;
  text-align: center;
}

#manageQuestionView .search {
  width: 400px;
  padding-left: 100px;
  margin: 0 auto 20px;
}
</style>
