<template>
  <div id="addQuestionView">
    <p>创建题单</p>
    <a-form :model="form" label-align="left" size="medium">
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="请输入标题" />
      </a-form-item>

      <a-form-item field="content" label="题目描述">
        <!--        <MdEditor :value="form.content" :handle-change="onContentChange" />-->
        <a-input v-model="form.content" placeholder="请输入题单描述" />
      </a-form-item>

      <a-form-item field="questionList" label="题目列表">
        <a-input-tag
          v-model="form.questionList"
          placeholder="请选择题号"
          allow-clear
        />
      </a-form-item>

      <div style="margin-top: 16px" />
      <a-form-item>
        <a-button type="primary" style="min-width: 200px" @click="doSubmit()"
          >提交
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import MdEditor from "@/components/Editor/MdEditor.vue";
import { QuestionControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
// 如果页面地址包含 update，视为更新页面
const updatePage = route.path.includes("update");

let form = ref({
  title: "",
  content: "",
  questionList: [],
});

/**
 * 根据题目 id 获取老的数据
 */
const loadData = async () => {
  const id = route.query.id;
  // if (!id) {
  //   return;
  // }
  // const res = await QuestionControllerService.getQuestionByIdUsingGet(
  //   id as any
  // );
  // if (res.code === 0) {
  //   form.value = res.data as any;
  // } else {
  //   message.error("加载失败，" + res.message);
  // }
};

onMounted(() => {
  loadData();
});

const router = useRouter();
const doSubmit = async () => {
  // alert(JSON.stringify(form.value));
  // 区分 [更新] 还是 [创建]
  if (updatePage) {
    // const res = await QuestionControllerService.updateQuestionUsingPost(
    //   form.value
    // );
    // if (res.code === 0) {
    //   message.success("更新成功");
    // } else {
    //   message.error("更新失败，" + res.message);
    // }
  } else {
    const res = await QuestionControllerService.addQuestionLessonUsingPost(
      form.value
    );
    // alert(JSON.stringify(res));
    if (res.code === 0) {
      message.success("创建成功");
      await router.push({
        path: "/manage/question_lesson",
      });
    } else {
      message.error("创建失败，" + res.message);
    }
  }
};

const onContentChange = (value: string) => {
  form.value.content = value;
};
</script>

<style scoped>
#addQuestionView {
  margin: 0 20px;
  padding: 0 20px;

  overflow-x: hidden;
}

#addQuestionView p {
  font-size: 38px;
  font-weight: bold;
  margin: 0 auto 48px;
  text-align: center;
}
</style>
