<template>
  <div class="right">
    <div class="user" @mousedown="toUserPage($event)" v-if="loginUser">
      <a-avatar>
        <img
          v-if="loginUser.userAvatar"
          alt="avatar"
          :src="loginUser?.userAvatar"
        />
        <img
          v-else
          alt="avatar"
          src="../../assets/userAvatar(Define).png"
          style="background-color: #fff"
        />
      </a-avatar>
      <div class="userName">{{ loginUser?.userName }}</div>
    </div>
    <div class="notice">
      <img src="../../assets/ring0.png" style="height: 24px" />
    </div>
    <div class="exit" @click="userLogout">
      <img src="../../assets/exit.png" style="height: 24px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { onMounted, onUnmounted, ref } from "vue";
import { useStore } from "vuex";
import { UserControllerService } from "../../../generated";
import message from "@arco-design/web-vue/es/message";

const noticeImgSrc = ref("../assets/ring0.svg");

const router = useRouter();
const store = useStore();

// 路由跳转时，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 默认主页
const selectedKeys = ref(["/"]);

const loginUser = ref(JSON.parse(localStorage.getItem("loginUser") || "{}"));

// 个人中心
// const userPage = () => {
//   router.push({
//     path: "/center",
//     replace: true,
//   });
// };

const toUserPage = (event: MouseEvent) => {
  event.preventDefault();
  const path = "/center";

  if (event.button === 0) {
    // 左键点击，使用 router.push 在当前标签页跳转
    router.push({
      path: path,
    });
  } else if (event.button === 1) {
    // 中键点击，使用 window.open 在新标签页打开链接
    window.open(window.location.origin + router.resolve(path).href, "_blank");
  }
};

const userLogout = async () => {
  // 显示确认对话框
  if (window.confirm("确定要退出吗？")) {
    let res = await UserControllerService.userLogoutUsingPost();
    if (res.code == 0) {
      await store.dispatch("user/userLogout", null);

      // await nextTick();

      // 用户退出登录，删除本地缓存的 loginUser
      localStorage.removeItem("loginUser");

      message.success("用户退出");
      router.push({
        path: "/user/login",
        replace: true,
      });
    } else {
      message.error("用户退出失败");
    }
  }
};
</script>

<style scoped>
.left,
.right {
  display: flex;
  align-items: center;
  height: 64px;
  /*background-color: mediumslateblue;*/
  color: white;
  font-size: 18px;
}

.left {
  width: auto;
  position: fixed;
  left: 10vw;
}

.left img {
  height: 56px;
  padding: 15px 0 15px 15px;
}

.custom-menu ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 12px;
  margin: 6px 6px 6px 0;
}

.custom-menu li {
  cursor: pointer;
  width: 100px;
  margin: 8px;
  padding: 4px;
  font-size: 20px;
  transition: border-bottom 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.custom-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: white;
  transition: height 0.2s ease;
}

.custom-menu li:hover::after {
  height: 1px;
}

.custom-menu li ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.custom-menu li:hover ul {
  display: block;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 250px;
  position: fixed;
  right: 5vw;
  margin-top: 2px;
  margin-right: 10vw;
}

.right .notice,
.right .user {
  position: static;
}

.right .user {
  display: flex;
  /*margin-top: 3px;*/
  justify-content: space-between;

  height: 36px;
  padding: 13px;
  margin-top: 1px;
  cursor: pointer;
  filter: brightness(0.8); /* 降低图片的亮度 */
  transition: filter 0.3s ease;
}

/* .user:hover中间没有空格，表示选择user的子元素*/
.right .user:hover {
  filter: brightness(1); /* 在鼠标悬停时恢复图片的亮度 */
  /*color: #42b983;*/
}

.right .user img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.right .user .userName {
  width: auto;
  margin: auto 10px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right .notice img,
.right .exit img {
  height: 36px;
  padding: 13px;
  cursor: pointer;
  filter: brightness(0.8); /* 降低图片的亮度 */
  transition: filter 0.3s ease;
}

.right .notice img:hover,
.right .exit img:hover {
  filter: brightness(2); /* 在鼠标悬停时恢复图片的亮度 */
}

.right .notice {
  margin-top: 3px;
}
</style>
