import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import { routes } from "@/router/routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach(
//   (
//     to: RouteLocationNormalized,
//     from: RouteLocationNormalized,
//     next: Function
//   ) => {
//     const defaultTitle = "EzCoding"; // 这里设置您的项目名称
//     document.title = `${
//       to.meta.title ? to.meta.title + " - " : ""
//     }${defaultTitle}`;
//     next();
//   }
// );

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (path?: string) => void
  ) => {
    const defaultTitle = "EzCoding"; // 这里设置您的项目名称
    document.title = `${
      to.meta.title ? to.meta.title + " - " : ""
    }${defaultTitle}`;
    next();
  }
);

export default router;
