<template>
  <div class="home">
    <!--    <p class="hh1">Welcome to EzCoding</p>-->
    <!--    <div class="divider"></div>-->
    <!--    <p class="hh2">The Best Algorithm Practice Platform</p>-->
    <!--    <button class="btn" @click="goQuestions">Try now</button>-->

    <p class="hh1">欢迎来到 EzCoding</p>
    <div class="divider"></div>
    <p class="hh2">最棒的算法练习平台</p>
    <button class="btn" @click="goQuestions">进入</button>
  </div>
</template>

<style>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  margin-top: 200px;
  color: #fff;
  /*font-style: Microsoft YaHei;*/
}

.home .hh1,
.home .hh2 {
  -webkit-text-stroke: 1px black;
  user-select: none;

  letter-spacing: 5px; /* 设置字间距 */
  font-weight: 800;
}

.home .hh1 {
  font-size: 50px;
  margin-top: 70px;
  margin-bottom: 20px;
}

.home .hh2 {
  font-size: 56px;
  letter-spacing: 15px; /* 设置字间距 */
  margin: 40px 0;
}

.home .hh1:first-child {
  margin-top: 70px;
}

.home .hh1:last-child {
  margin-bottom: 0;
}

.home .divider {
  height: 3px;
  width: 400px;
  background-color: #fff;
  user-select: none;
}

.home .btn {
  height: 54px;
  width: 200px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #fff;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  user-select: none;
}

.home .btn:hover {
  /*background-color: #000;*/
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
</style>

<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const goQuestions = () => {
  router.push("/questions");
};

const value = ref();
const onChange = (v: string) => {
  value.value = v;
  console.log(v);
};
</script>
