<template>
  <div id="questionLessonView">
    <p>题单</p>
    <!--    边距-->
    <a-divider size="0" />

    <!--    表单-->
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <template #title="{ record }">
        <div class="title">
          {{ record.title }}
        </div>
      </template>

      <template #content="{ record }">
        <div
          class="content"
          style="width: 400px; height: 50px; overflow: hidden"
        >
          {{ record.content }}
        </div>
      </template>

      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toQuestionPage(record)">
            查看
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";

const tableRef = ref();

const store = useStore();
// const user = store.state.user;
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionQueryRequest>({
  title: "",
  pageSize: 10,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionLessonByPageUsingPost(
    searchParams.value
  );
  if (res.code === 0) {
    // 获取 records
    const records = res.data.records;

    // 将每个记录中的 questionList 转换为 List<Long>
    records.forEach((record: any) => {
      // 将 JSON 字符串转换为数组
      const questionListArray = JSON.parse(record.questionList);
      // 将数组中的每个元素转换为数字
      const questionList = questionListArray.map(Number);
      // 将转换后的 questionList 设置回 record 对象
      record.questionList = questionList;
    });
    // alert(JSON.stringify(records));

    // dataList.value = res.data.records;
    dataList.value = records;
    total.value = res.data.total;
  } else {
    message.error("加载失败，" + res.message);
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  // 在页面加载时读取 localStorage 里的状态信息
  const loginUserString = localStorage.getItem("loginUser");
  if (loginUserString) {
    const loginUser = JSON.parse(loginUserString);
    if (loginUser) {
      // 如果存在登录信息，更新到 Vuex 的状态中
      store.commit("updateUser", loginUser);
    }
  }
  loadData();
});

const columns = [
  {
    title: "题单名称",
    slotName: "title",
  },
  {
    title: "题单描述",
    slotName: "content",
  },
  {
    slotName: "optional",
  },
];

// 页面修改事件
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const router = useRouter();

const toQuestionPage = (lesson: any) => {
  if (loginUser?.userRole !== ACCESS_ENUM.NOT_LOGIN) {
    const path = `/lesson/question_list`;
    router.push({
      path,
      query: {
        title: lesson.title,
        questionList: JSON.stringify(lesson.questionList), // Pass questionList as query parameter
      },
    });
  } else {
    message.info("请先登录");
    router.push({
      path: "/user/login",
      query: {
        redirect: "/lesson/question_list",
      },
    });
  }
};

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
</script>

<style scoped>
#questionLessonView {
  max-width: 1280px;
  margin: 0 auto;
}

#questionLessonView p {
  text-align: center;
  margin: 10px 0 50px 0;
  font-size: 40px;
  font-weight: 600;
}

#questionLessonView .search {
  width: 400px;
  padding-left: 100px;
  margin: 0 auto;
}

#questionLessonView .content {
  width: 400px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
