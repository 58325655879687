<template>
  <div class="left">
    <div class="logo">
      <img src="../../assets/EzCoding-logo-white.svg" />
    </div>

    <GlobalHeaderMenu></GlobalHeaderMenu>
  </div>
  <GlobalHeaderLogined
    v-if="
      loginUser?.userRole !== undefined &&
      loginUser?.userRole !== ACCESS_ENUM.NOT_LOGIN
    "
  />
  <GlobalHeaderNotLogin v-else />
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useStore } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import GlobalHeaderLogined from "@/components/Header/GlobalHeaderLogined.vue";
import GlobalHeaderNotLogin from "@/components/Header/GlobalHeaderNotLogin.vue";
import GlobalHeaderMenu from "@/components/Header/GlobalHeaderMenu.vue";

const router = useRouter();
const store = useStore();
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");

// 路由跳转时，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 默认主页
const selectedKeys = ref(["/"]);
</script>

<style scoped>
.left,
.right {
  display: flex;
  align-items: center;
  height: 64px;
  /*background-color: mediumslateblue;*/
  color: white;
  font-size: 18px;
}

.left {
  width: auto;
  position: fixed;
  left: 10vw;
}

.left img {
  height: 56px;
  padding: 15px 0 15px 15px;
}

.custom-menu ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 12px;
  margin: 6px 6px 6px 0;
}

.custom-menu li {
  cursor: pointer;
  width: 100px;
  margin: 8px;
  padding: 4px;
  font-size: 20px;
  transition: border-bottom 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.custom-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: white;
  transition: height 0.2s ease;
}

.custom-menu li:hover::after {
  height: 1px;
}

.custom-menu li ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.custom-menu li:hover ul {
  display: block;
}
</style>
