<template>
  <div id="app">
    <template v-if="route.path.startsWith('/user')">
      <router-view />
    </template>
    <template v-else>
      <!--  withoutBur虽然报错，但是能用 -->
      <BasicLayoutNew v-if="!route.meta.withoutBlur" />
      <BasicLayoutNewWithoutBlur v-else />
    </template>
  </div>
</template>

<style>
#app {
}

/* 直接写在vue.app文件中*/
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  /*overflow: scroll;*/
  /*overflow: overlay;*/
}

/* 滑块部分*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #fff;
  /*background-color: #ecb0c1;*/
}

/* 轨道部分 */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ddd;
  /*background: #ddd;*/
  /*// border-radius: 5px;*/
    backdrop-filter: blur(10px); /* 添加模糊效果 */
  background-color: rgba(255, 255, 255, 0.1); /* 使用半透明的背景颜色 */
  /*background-color: #3fabe2;*/
}
</style>

<script setup lang="ts">
import { useRoute } from "vue-router";
import BasicLayoutNew from "@/layouts/BasicLayout.vue";
import BasicLayoutNewWithoutBlur from "@/layouts/BasicLayoutWithoutBlur.vue";

const route = useRoute();
</script>
