import ACCESS_ENUM from "@/access/accessEnum";
import router from "@/router";
import store from "@/store";
import checkAccess from "@/access/checkAccess";
import message from "@arco-design/web-vue/es/message";

const user = store.state.user;
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
// to：即将进入的目标路由
// from：正要离开的当前路由
// next：钩子函数，调用next会继续路由，也可以重定位到别的路由
router.beforeEach(async (to, from, next) => {
  // 仅管理员可见
  // if (to.meta?.access === ACCESS_ENUM.ADMIN) {
  //   if (user.loginUser?.userRole !== ACCESS_ENUM.ADMIN) {
  //     next("/noAuth");
  //     return;
  //   }
  // }

  // 检查用户状态
  // console.log("用户状态：", user.loginUser);

  // 自动登录
  // console.log("before loginUser：" + loginUser);

  // 直接自动登录
  // await store.dispatch("user/getLoginUser");

  let loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
  if (!loginUser || !loginUser.userRole) {
    // 添加 await ，等用户登录成功之后，再执行后续的代码
    await store.dispatch("user/getLoginUser");
    loginUser = store.state.user.loginUser;
  }

  // console.log("after loginUser：" + loginUser);

  /**
   * 权限判断：
   * 1. 页面需要登录帐号才可以访问
   * 1.1 未登录用户，跳转到登录界面
   * 1.2 已登录用户，查看是否需要权限校验
   *
   * 2. 页面不需要权限，直接跳转到目标页面
   */
  const needAccess = (to.meta?.access as string) ?? ACCESS_ENUM.NOT_LOGIN;
  // 1. 页面需要登录帐号才可以访问
  if (needAccess !== ACCESS_ENUM.NOT_LOGIN) {
    // 1.1 未登录用户，跳转到登录界面
    if (
      !loginUser ||
      !loginUser.userRole ||
      loginUser.userRole === ACCESS_ENUM.NOT_LOGIN
    ) {
      // alert(JSON.stringify(loginUser));

      message.info("请先登录");
      next(`/user/login?redirect=${to.fullPath}`);
      return;
    }
    // 1.2 权限不足，跳转到无权限界面
    if (!checkAccess(loginUser, needAccess)) {
      next("/noAuth");
      return;
    }
  }

  next();
});
