<template>
  <div id="userLogin">
    <h2 style="color: #eee">登录</h2>
    <form @submit.prevent="handleLogin">
      <label>
        <!--        账号:-->
        <input
          type="text"
          v-model="loginAccount"
          placeholder="account"
          required
          @input="checkAccount"
        />

        <span v-if="isLoginAccountError" class="length-warning">X</span>
      </label>
      <label>
        <!--        密码:-->
        <input
          type="password"
          v-model="loginPassword"
          placeholder="password"
          required
          @input="checkPassword"
        />
        <span v-if="isLoginPasswordError" class="length-warning">X</span>
      </label>
      <!-- 密码长度提示 -->
      <!--      <div v-if="showPasswordLengthWarning" class="password-length-warning">-->
      <!--        密码长度至少为8位-->
      <!--      </div>-->
      <label class="remember-me-label">
        <input
          id="rememberMe"
          type="checkbox"
          v-model="rememberMe"
          class="checkbox-input"
        />
        记住我
      </label>
      <button type="submit">登录</button>
    </form>
    <div class="or">or</div>
    <div class="login_help" @click="callAdmin">忘记密码？请联系管理员</div>
  </div>
</template>

<style>
#userLogin {
  display: flex;
  height: 500px;
  width: 400px;
  margin-bottom: 70px;
  flex-direction: column;
  /*background-color: #42b983;*/
  backdrop-filter: blur(50px); /* 添加模糊效果 */
  background-color: rgba(255, 255, 255, 0.1); /* 使用半透明的背景颜色 */
  border-radius: 15px; /* 设置边框弧度 */
}

#userLogin h2 {
  font-size: 25px;
  margin-top: 60px;
  margin-bottom: 20px;
  user-select: none;
}

#userLogin form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

#userLogin form label input[type="text"],
#userLogin form label input[type="password"],
#userLogin form button {
  width: 250px;
  height: 50px; /* 设置输入框高度 */
  border-radius: 10px; /* 设置边框弧度 */
  margin: 15px auto;
}

#userLogin form label {
  color: #fff;
}

#userLogin form label input[type="text"],
#userLogin form label input[type="password"] {
  border: 1px solid #ccc; /* 设置边框 */
  padding: 0 15px; /* 设置输入框内边距 */
}

.remember-me-label {
  display: flex;
  align-items: center;
  /*margin-top: 5px;*/
  margin-right: 180px;
  /*margin-right: 120px;*/
  font-size: 17px;
}

.checkbox-input {
  margin-right: 8px; /* 调整复选框与文字之间的间距 */
  width: 20px; /* 调整复选框的宽度 */
  height: 20px; /* 调整复选框的高度 */
}

#userLogin form button {
  width: 200px;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px; /* 设置边框弧度 */
  border: 1px solid #ccc; /* 设置边框 */
  font-size: 20px;
  font-weight: 500;
  background-color: #fff;
  color: #050505;
  cursor: pointer;
}

.or {
  margin: 5px 0;
  font-size: 15px;
  color: #ddd;
  text-align: center;
  user-select: none;
}

.login_help {
  margin: 10px 0;
  padding-left: 10px;
  font-size: 15px;
  color: #ddd;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

#userLogin .login_help:hover {
  color: #fff;
}

.password-length-warning {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

label {
  position: relative; /* 设置 label 为相对定位，这样我们可以在它内部使用绝对定位 */
}

.length-warning {
  position: absolute; /* 使用绝对定位将元素定位在输入框的右侧 */
  right: 10px; /* 将元素向右移动 10px，使其与输入框的右边界对齐 */
  top: 50%; /* 将元素向上移动 50%，使其垂直居中 */
  transform: translateY(
    -50%
  ); /* 使用 transform 属性的 translateY 函数将元素向上移动自身高度的一半，以确保元素完全居中 */
  color: red;
  font-size: 20px;
  font-weight: 600;
}
</style>

<script setup lang="ts">
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserControllerService, UserLoginRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";

const router = useRouter();
const store = useStore();

// 从本地存储中获取账号和密码，如果不存在则为空字符串
const storedAccount = localStorage.getItem("account") || "";
const storedPassword = localStorage.getItem("password") || "";
// 判断本地存储中是否存在账号和密码
const hasAccountPassword = storedAccount !== "" && storedPassword !== "";
// alert(hasAccountPassword);

// 定义响应式变量，使用三元表达式确定初始值
const loginAccount = ref(storedAccount);
const loginPassword = ref(storedPassword);
const rememberMe = ref(hasAccountPassword);
const isLoginAccountError = ref(false);
const isLoginPasswordError = ref(false);
const accountPattern = /^[a-zA-Z][a-zA-Z0-9]{3,}$/;
const passwordPattern = /^(?=.*[0-9a-zA-Z])[0-9a-zA-Z]{8,}$/;

const form = reactive({
  userAccount: "",
  userPassword: "",
} as UserLoginRequest);

/**********************************************************************************/
const checkAccount = () => {
  isLoginAccountError.value =
    (loginAccount.value.length < 4 && loginAccount.value.length > 0) ||
    !accountPattern.test(loginAccount.value);
};

const checkPassword = () => {
  isLoginPasswordError.value =
    (loginPassword.value.length < 8 && loginPassword.value.length > 0) ||
    !passwordPattern.test(loginPassword.value);
};
/**********************************************************************************/

const handleLogin = async () => {
  /**********************************************************************************/
  if (isLoginAccountError.value) {
    message.error("账号只能是英文字母+数字的组合(首位字母)，且至少4位");
    return;
  }

  if (isLoginPasswordError.value) {
    message.error("密码必须包含数字或英文字母，且至少8位");
    return;
  }
  /**********************************************************************************/

  form.userAccount = loginAccount.value;
  form.userPassword = loginPassword.value;
  // alert("in handleSubmit" + JSON.stringify(form));

  const res = await UserControllerService.userLoginUsingPost(form);
  // alert("res: " + JSON.stringify(res));

  if (res.code === 0) {
    // 保存用户名和密码到本地存储
    if (rememberMe.value) {
      localStorage.setItem("account", loginAccount.value);
      localStorage.setItem("password", loginPassword.value);
    }

    try {
      await store.dispatch("user/getLoginUser", form);
      // console.log("after dispatch");
    } catch (error) {
      // console.log("Error during dispatch: " + error);
    }

    localStorage.setItem("loginUser", JSON.stringify(res.data));
    message.success("登录成功");

    const redirect = router.currentRoute.value.query.redirect;
    // alert("redirect: " + redirect);
    if (redirect) {
      // 如果存在 redirect 参数，跳转到该参数指定的页面
      // 会报错但是不会影响功能
      router.push({
        path: redirect,
        replace: true,
      });
    } else {
      // 如果不存在 redirect 参数，跳转到默认页面
      router.push({
        path: "/",
        replace: true,
      });
    }
  } else {
    // alert("登录失败: " + res.message);
    message.error("登录失败");
  }
};

const callAdmin = () => {
  message.info("请联系管理员");

  router.push({
    path: "/about",
    replace: true,
  });
};
</script>
