<template>
  <div id="questionSubmission">
    <!-- 表单内容 -->
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
    >
      <template #judgeInfo="{ record }">
        <span
          :class="{
            'text-green': record.judgeInfo.message === 'Accepted',
            'text-red': record.judgeInfo.message !== 'Accepted',
          }"
        >
          {{ record.judgeInfo.message }}
        </span>
      </template>
      <template #judgeInfo_time="{ record }">
        {{ record.judgeInfo.time + " ms" }}
      </template>
      <template #judgeInfo_memory="{ record }">
        {{ (record.judgeInfo.memory / 1024).toFixed(2) + " MB" }}
      </template>
      <template #status="{ record }">
        {{ statusMap[parseInt(record.status)] || "未知状态" }}
      </template>
      <template #createTime="{ record }">
        {{ moment(record.createTime).format("YYYY-MM-DD") }}
      </template>

      <template #optional="{ record }">
        <a-button @click="showCode(record)">查看</a-button>
        <div class="code" v-if="record.isShowCode">
          <icon-close class="close-button" @click="closeCode()" size="25" />
          <pre><code>{{ record.code }}</code></pre>
          <br />
          <a-button class="copy-button" @click="copyCode(record)" type="outline"
            >复制
          </a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, watchEffect } from "vue";
import {
  QuestionControllerService,
  QuestionSubmitQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";
import moment from "moment";
import { defineProps, withDefaults } from "vue/dist/vue";
import hljs from "highlight.js";

const tableRef = ref();

const dataList = ref([]);
const total = ref(0);
const loginUser = localStorage.getItem("loginUser");
const userId = loginUser ? JSON.parse(loginUser).id : undefined;

const router = useRouter();

interface Props {
  questionId: number;
}

const props = withDefaults(defineProps<Props>(), {});

const searchParams = ref<QuestionSubmitQueryRequest>({
  questionId: props.questionId,
  language: undefined,
  userId: userId,
  pageSize: 10,
  current: 1,
});

const loadData = async () => {
  const res = await QuestionControllerService.listQuestionSubmitByPageUsingPost(
    {
      ...searchParams.value,
      sortField: "createTime",
      sortOrder: "descend",
    }
  );
  if (res.code === 0) {
    dataList.value = res.data.records;
    total.value = res.data.total;
  } else {
    // message.error("加载失败，" + res.message);

    if (res.message === "未登录") {
      // 删除本地缓存中的 loginUser 数据
      localStorage.removeItem("loginUser");
      // 用户没有登录，跳转到登录页面，并在 URL 中添加一个 redirect 参数
      message.info("加载失败，请先登录");
      // 获取当前页面的路径作为跳转路径
      const redirectPath = window.location.pathname;
      // alert(redirectPath);
      router.push({
        path: "/user/login",
        query: {
          redirect: redirectPath,
        },
      });
    } else {
      message.error("加载失败，" + res.message);
    }
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  loadData();
});

const statusMap = reactive({
  0: "待判题",
  1: "判题中",
  2: "运行成功",
  3: "运行失败",
});

const columns = [
  {
    title: "提交时间",
    slotName: "createTime",
  },
  {
    title: "编程语言",
    dataIndex: "language",
  },
  {
    title: "判题信息",
    slotName: "judgeInfo",
  },
  {
    title: "运行时间",
    slotName: "judgeInfo_time",
  },
  {
    title: "运行内存",
    slotName: "judgeInfo_memory",
  },
  {
    title: "判题状态",
    slotName: "status",
  },
  {
    title: "查看代码",
    slotName: "optional",
  },
];

const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const navigateToQuestion = (questionId: number, questionSubmitId: number) => {
  alert("跳转到题目详情页" + questionId + "提交id" + questionSubmitId);
  // router.push({
  //   path: `/view/question/${questionId}/submit/${submitId}`,
  // });
};

// const preformattedCode = (code: string) => {
//   alert(code);
// };

const showCode = (record: any) => {
  record.isShowCode = true;
  // 进行代码高亮处理
  const codeBlocks = document.querySelectorAll(".code");
  codeBlocks.forEach((block: any) => {
    hljs.highlightBlock(block);
  });

  document.body.style.pointerEvents = "none"; // 添加这一行
  document.addEventListener("click", handleClickOutside, { capture: true });
};

// 关闭代码查看时，恢复全局点击事件的正常传播
const closeCode = () => {
  dataList.value.forEach((record: any) => {
    record.isShowCode = false;
  });
  document.body.style.pointerEvents = "auto"; // 添加这一行
  document.removeEventListener("click", handleClickOutside, { capture: true });

  // document.body.style.pointerEvents = "auto"; // 恢复全局点击事件
};

// 处理全局点击事件
const handleClickOutside = (event: any) => {
  const codeHighlight = document.querySelector(".code");
  if (codeHighlight && !codeHighlight.contains(event.target)) {
    closeCode(); // 点击代码块外部时关闭代码查看
  }
};

const copyCode = (record: any) => {
  const code = record.code || "";
  // 复制 Java 代码到剪贴板
  navigator.clipboard
    .writeText(code)
    .then(() => {
      message.success("复制成功");
    })
    .catch((error) => {
      message.error("复制失败");
    });

  closeCode(); // 点击代码块外部时关闭代码查看
};
/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
</script>

<style scoped>
#questionSubmission {
  max-width: 1400px;
  margin: 0 auto;
}

.text-green {
  color: #40c057;
}

.text-red {
  color: red;
}

#questionSubmission .code {
  position: fixed;
  min-width: 300px;
  min-height: 200px;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  /*border: 1px solid #ddd;*/
  padding: 40px;
  font-size: 16px;
  color: #333;
  /*backdrop-filter: blur(100px); !* 添加模糊效果 *!*/
  /*background-color: rgba(255, 255, 255, 0.7);*/
  background-color: rgba(250, 250, 250);
  border-radius: 10px;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: auto; /* 添加这一行 */
  transition: box-shadow 0.3s ease; /* 添加这一行 */
}

#questionSubmission .code:hover {
  box-shadow: 0 0 33px 5px rgba(0, 0, 0, 0.7);
}

#questionSubmission .copy-button {
  position: absolute;
  bottom: 25px;
}

#questionSubmission .close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  transition: transform 0.1s ease; /* 添加这一行 */
}

#questionSubmission .close-button:hover {
  cursor: pointer;
  transform: scale(1.1); /* 添加这一行 */
}
</style>
