<template>
  <div class="about">
    <img src="../assets/pdx.png" />
    <p>网页作者：xiaozz</p>
    <p>微信：AZzzzzxj</p>
  </div>
</template>

<style>
.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.about img {
  height: 256px;
  width: 256px;
}

.about p {
  margin: 50px auto 0;
  color: #274b65;
  /*-webkit-text-stroke: 1px black;*/
  font-size: 36px;
  font-weight: 700;
}
</style>
