<template>
  <div id="questionsView">
    <p>{{ questionListTitle }}</p>
    <!--    <div class="search">-->
    <!--      &lt;!&ndash;    搜索框&ndash;&gt;-->
    <!--      <a-form :model="searchParams" layout="inline">-->
    <!--        <a-form-item field="title" label="名称" style="min-width: 240px">-->
    <!--          <a-input v-model="searchParams.title" placeholder="请输入名称" />-->
    <!--        </a-form-item>-->
    <!--        <a-form-item>-->
    <!--          <a-button type="primary" @click="doSubmit()">查询</a-button>-->
    <!--        </a-form-item>-->
    <!--      </a-form>-->
    <!--    </div>-->

    <!--    边距-->
    <a-divider size="0" />

    <!--    表单-->
    <a-table
      :ref="tableRef"
      :columns="columns"
      :data="dataList"
      :pagination="{
        showTotal: true,
        pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
      }"
      @page-change="onPageChange"
      style="min-width: 1000px"
    >
      <template #tags="{ record }">
        <a-space
          wrap
          style="max-width: 300px; max-height: 30px; overflow: hidden"
        >
          <a-tag v-for="(tag, index) of record.tags" :key="index" color="blue"
            >{{ tag }}
          </a-tag>
        </a-space>
      </template>

      <template #submitNum="{ record }">
        {{ record.submitNum }}
      </template>

      <template #acceptedNum="{ record }">
        {{
          `${
            record.submitNum
              ? ((record.acceptNum / record.submitNum) * 100).toFixed(1)
              : "0"
          }%`
        }}
      </template>

      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="toQuestionPage(record)">
            做题
          </a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from "vue";
import {
  Question,
  QuestionControllerService,
  QuestionListRequest,
  QuestionQueryRequest,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";

const tableRef = ref();
const route = useRoute();
const questionListTitle = route.query.title;
// alert(questionListTitle);

const store = useStore();
// const user = store.state.user;
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");

const dataList = ref([]);
const total = ref(0);
const searchParams = ref<QuestionQueryRequest>({
  title: "",
  tags: [],
  pageSize: 10,
  current: 1,
});

const form = ref<QuestionListRequest>({
  questionList: [],
  pageSize: 10,
  current: 1,
});

const loadData = async () => {
  form.value.questionList = JSON.parse(route.query.questionList || "[]"); // Parse questionList from query parameter
  // alert(questionList);
  if (form.value.questionList.length > 0) {
    // Load questions based on questionList
    const res = await QuestionControllerService.listQuestionVoByIdsUsingPost(
      form.value
    );
    // alert(JSON.stringify(res));
    if (res.code === 0) {
      dataList.value = res.data;
      total.value = res.data.length;
    } else {
      message.error("加载失败，" + res.message);
    }
  } else {
    // Load questions based on search parameters
    const res = await QuestionControllerService.listQuestionVoByPageUsingPost(
      searchParams.value
    );
    if (res.code === 0) {
      dataList.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败，" + res.message);
    }
  }
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
watchEffect(() => {
  loadData();
});

/**
 * 页面加载时，请求数据
 */
onMounted(() => {
  // 在页面加载时读取 localStorage 里的状态信息
  const loginUserString = localStorage.getItem("loginUser");
  if (loginUserString) {
    const loginUser = JSON.parse(loginUserString);
    if (loginUser) {
      // 如果存在登录信息，更新到 Vuex 的状态中
      store.commit("updateUser", loginUser);
    }
  }
  loadData();
});

const columns = [
  {
    title: "题号",
    dataIndex: "id",
  },
  {
    title: "题目名称",
    dataIndex: "title",
  },
  {
    title: "标签",
    slotName: "tags", // 插槽
  },
  {
    title: "提交数",
    slotName: "submitNum",
  },
  {
    title: "通过率",
    slotName: "acceptedNum",
  },
  {
    slotName: "optional",
  },
];

// 页面修改事件
const onPageChange = (page: number) => {
  searchParams.value = {
    ...searchParams.value,
    current: page,
  };
};

const router = useRouter();

/**
 * 跳转到做题页面
 * @param question
 */
const toQuestionPage = (question: Question) => {
  if (loginUser?.userRole != ACCESS_ENUM.NOT_LOGIN) {
    // 用户已经登录，直接跳转到问题页面
    const path = `/view/question/${question.id}`;
    window.open(window.location.origin + router.resolve(path).href, "_blank");
  } else {
    // 用户没有登录，跳转到登录页面，并在 URL 中添加一个 redirect 参数
    message.info("请先登录");
    router.push({
      path: "/user/login",
      query: {
        redirect: `/view/question/${question.id}`,
      },
    });
  }
};

/**
 * 确认搜索，重新加载数据
 */
const doSubmit = () => {
  // 这里需要重置搜索页号
  searchParams.value = {
    ...searchParams.value,
    current: 1,
  };
};
</script>

<style scoped>
#questionsView {
  max-width: 1280px;
  margin: 0 auto;
}

#questionsView p {
  text-align: center;
  margin: 10px 0 50px 0;
  font-size: 40px;
  font-weight: 600;
}

#questionsView .search {
  width: 400px;
  padding-left: 100px;
  margin: 0 auto;
}
</style>
