<template>
  <div id="QuestionSolution">
    <!--    <a-button class="btn" @click="copyCode" shape="round" type="primary"-->
    <!--      >复制答案-->
    <!--    </a-button>-->
    <a-button
      v-if="props.question.answer !== '暂不提供'"
      class="btn"
      @click="copyCode()"
      shape="round"
      type="primary"
    >
      复制答案
    </a-button>

    <MdViewer :value="props.question.answer || ''" />

    <!--    <button @click="copyJavaCode">复制答案</button>-->
  </div>
</template>

<script setup lang="ts">
import { QuestionVO } from "../../../generated";
import { defineProps, withDefaults } from "vue/dist/vue";
import MdViewer from "@/components/MdViewer.vue";
import message from "@arco-design/web-vue/es/message";

interface Props {
  question: QuestionVO;
}

const props = withDefaults(defineProps<Props>(), {});

const copyCode = () => {
  const answer = props.question.answer || "";
  const javaCodeStartIndex = answer.indexOf("```") + 4; // 找到 Java 代码开始位置
  const javaCodeEndIndex = answer.indexOf("```", javaCodeStartIndex); // 找到 Java 代码结束位置
  const javaCode = answer
    .substring(javaCodeStartIndex, javaCodeEndIndex)
    .trim(); // 提取 Java 代码内容
  // 复制 Java 代码到剪贴板
  navigator.clipboard
    .writeText(javaCode)
    .then(() => {
      message.success("复制成功");
    })
    .catch((error) => {
      message.error("复制失败");
    });
};
</script>

<style scoped>
#QuestionSolution {
  /*display: flex;*/

  padding: 20px;
  font-size: 16px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.7); /* 使用半透明的背景颜色 */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#QuestionSolution button {
}
</style>
