import { RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import NoAuthView from "@/views/NoAuthView.vue";
import ACCESS_ENUM from "@/access/accessEnum";
import UserLayoutNew from "@/layouts/UserLayout.vue";
import UpdateQuestionView from "@/views/question/UpdateQuestionView.vue";
import ManageQuestionView from "@/views/question/ManageQuestionView.vue";
import QuestionsView from "@/views/question/QuestionsView.vue";
import AboutView from "@/views/AboutView.vue";
import LoginAndRegisterView from "@/views/user/LoginAndRegisterView.vue";
import UserView from "@/views/user/UserView.vue";
import DoQuestionView from "@/views/question/DoQuestionView.vue";
import AddQuestionView from "@/views/question/AddQuestionView.vue";
import QuestionLessonView from "@/views/questionLesson/QuestionLessonView.vue";
import AddQuestionLessonView from "@/views/questionLesson/AddQuestionLessonView.vue";
import UpdateQuestionLessonView from "@/views/questionLesson/UpdateQuestionLessonView.vue";
import ManageQuestionLessonView from "@/views/questionLesson/ManageQuestionLessonView.vue";
import QuestionListView from "@/views/questionLesson/QuestionListView.vue";

// const loginUser = localStorage.getItem("loginUser");
// const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
// alert(loginUser?.userRole);

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "主页",
    component: HomeView,
    meta: {
      title: "主页",
      withoutBlur: true,
    },
  },
  {
    path: "/user",
    name: "用户",
    component: UserLayoutNew,
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: LoginAndRegisterView,
      },
    ],
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/center",
    name: "用户中心",
    component: UserView,
    meta: {
      title: "个人中心",
      hideInMenu: true,
      withoutBlur: true,
    },
  },
  {
    path: "/questions",
    name: "题库",
    component: QuestionsView,
    meta: {
      title: "题库",
    },
  },
  {
    path: "/lesson/question",
    name: "题单",
    component: QuestionLessonView,
    meta: {
      title: "题单",
    },
  },
  {
    path: "/lesson/question_list",
    name: "题目列表",
    component: QuestionListView,
    meta: {
      title: "题目列表",
      hideInMenu: true,
    },
  },
  {
    path: "/view/question/:id",
    name: "在线做题",
    component: DoQuestionView,
    props: true,
    meta: {
      access: ACCESS_ENUM.USER,
      hideInMenu: true,
    },
  },
  {
    path: "/add/question",
    name: "创建题目",
    component: AddQuestionView,
    meta: {
      title: "创建题目",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  {
    path: "/update/question",
    name: "更新题目",
    component: UpdateQuestionView,
    meta: {
      title: "更新题目",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  {
    path: "/manage/question",
    name: "编辑题目",
    component: ManageQuestionView,
    meta: {
      title: "编辑题目",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  {
    path: "/add/question_lesson",
    name: "创建题单",
    component: AddQuestionLessonView,
    meta: {
      title: "创建题单",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  {
    path: "/update/question_lesson",
    name: "更新题单",
    component: UpdateQuestionLessonView,
    meta: {
      title: "更新题单",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  {
    path: "/manage/question_lesson",
    name: "编辑题单",
    component: ManageQuestionLessonView,
    meta: {
      title: "编辑题单",
      access: ACCESS_ENUM.ADMIN,
      hideInMenu: true,
    },
  },
  // {
  //   path: "/test",
  //   name: "测试",
  //   component: TestView,
  //   meta: {
  //     access: ACCESS_ENUM.USER,
  //   },
  // },
  {
    path: "/about",
    name: "关于",
    component: AboutView,
    meta: {
      title: "关于",
    },
  },
  {
    path: "/noAuth",
    name: "无访问权限",
    component: NoAuthView,
    meta: {
      hideInMenu: true,
    },
  },
];
