<template>
  <div id="QuestionContent">
    <div class="top">
      <div class="left">
        <div class="description">
          <p>题目描述</p>
          <div v-if="props.question">
            <MdViewer :value="props.question.content || ''" />
          </div>
          <div v-else>加载中...</div>
        </div>
        <br />
      </div>

      <div class="right">
        <div class="judgeConfig">
          <p>题目限制</p>
          <a-descriptions layout="horizontal">
            <a-descriptions-item label="时间限制">
              {{
                props.question && props.question.judgeConfig
                  ? props.question.judgeConfig.timeLimit ?? 0
                  : 0
              }}
              ms
            </a-descriptions-item>
            <br />
            <br />

            <a-descriptions-item label="内存限制">
              {{
                props.question && props.question.judgeConfig
                  ? Math.floor(
                      (props.question.judgeConfig.memoryLimit ?? 0) / 1024
                    )
                  : 0
              }}
              MB
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="tags" v-if="props.question">
          <p>题目标签</p>
          <a-tag
            class="tag"
            v-for="(tag, index) in props.question.tags"
            :key="index"
            color="blue"
          >
            {{ tag }}
          </a-tag>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="editor">
        <form id="myForm">
          <label for="language">编程语言:</label>
          <select id="language" name="language">
            <option value="java">Java</option>
            <option value="cpp">C++</option>
            <!--            <option value="go">Go</option>-->
            <!--            <option value="html">HTML</option>-->
          </select>
        </form>

        <br />

        <CodeEditor
          :value="form.code as string"
          :language="form.language"
          :handle-change="changeCode"
        />
      </div>

      <br />
      <button class="btn" @click="doSubmit">提交代码</button>
      <br />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  defineEmits,
  defineProps,
  onMounted,
  ref,
  watchEffect,
  withDefaults,
} from "vue";
import {
  QuestionControllerService,
  QuestionSubmitAddRequest,
  QuestionVO,
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import CodeEditor from "@/components/Editor/CodeEditor.vue";
import { useRouter } from "vue-router";
import store from "@/store";
import MdViewer from "@/components/MdViewer.vue";

const router = useRouter();

interface Props {
  id: string;
  question: QuestionVO;
  activeTab: string;
  code: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
  code: () => "",
});

interface FormType extends QuestionSubmitAddRequest {
  code: string;
}

// 使用新的类型定义
const form = ref<FormType>({
  language: "java",
  code: props.code,
});

const changeCode = (value: string) => {
  form.value.code = value;
  console.log("QuestionContent: " + form.value.code);
};

/**
 * 监听 searchParams 变量，改变时触发页面的重新加载
 */
// watchEffect(() => {});
const fillCode = (code: string) => {
  console.log("fillCode: " + code);
  form.value.code = code;
};

/**
 * 页面加载时，请求数据
 */
onMounted(async () => {
  // 在页面加载时读取 localStorage 里的状态信息
  const loginUserString = localStorage.getItem("loginUser");
  if (loginUserString) {
    const loginUser = JSON.parse(loginUserString);
    if (loginUser) {
      // 如果存在登录信息，更新到 Vuex 的状态中
      store.commit("updateUser", loginUser);
    }
  }
});

// 这行代码定义了一个名为 updateActiveTab 的自定义事件。
// emit 是一个函数，可以用来发出 updateActiveTab 事件。
const emit = defineEmits(["updateActiveTab"]);

// 保存代码到本地缓存
const saveCodeToLocal = () => {
  const loginUserString = localStorage.getItem("loginUser");
  const loginUser = JSON.parse(loginUserString || "");
  const userId = loginUser.id; // 获取当前登录用户的ID
  const questionId = props.question.id; // 获取题目ID
  if (userId && questionId && form.value.code) {
    const key = `${userId}_${questionId}_code`; // 组合键名
    localStorage.setItem(key, form.value.code); // 将代码存储到本地缓存
  }
};

/**
 * 提交代码
 */
const doSubmit = async () => {
  if (!props.question.id) {
    return;
  }
  if (!form.value.code) {
    message.error("请填写代码");
    return;
  }

  const res = await QuestionControllerService.doQuestionSubmitUsingPost({
    ...form.value,
    questionId: props.question.id,
  });
  if (res.code === 0) {
    message.success("提交成功");
    saveCodeToLocal(); // 在提交时保存代码到本地缓存
    emit("updateActiveTab", "submission");
  } else {
    // message.error("提交失败," + res.message);

    if (res.message === "未登录") {
      // 删除本地缓存中的 loginUser 数据
      localStorage.removeItem("loginUser");
      // 用户没有登录，跳转到登录页面，并在 URL 中添加一个 redirect 参数
      message.info("提交失败，请先登录");
      // 获取当前页面的路径作为跳转路径
      const redirectPath = window.location.pathname;
      // alert(redirectPath);
      router.push({
        path: "/user/login",
        query: {
          redirect: redirectPath,
        },
      });
    } else {
      message.error("提交失败，" + res.message);
    }
  }
};
</script>

<style scoped>
#QuestionContent {
  display: flex;
  flex-direction: column;
}

#QuestionContent p {
  color: black;
  font-size: 18px;
  font-weight: 550;
}

#QuestionContent .top {
  display: flex;

  padding: 20px;
  font-size: 16px;
  color: #333;
  background-color: rgba(255, 255, 255, 0.7); /* 使用半透明的背景颜色 */
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

#QuestionContent .top .left {
  width: 70vw;
  padding: 0 20px;
}

#QuestionContent .top .right {
  width: 30vw;
  margin-left: 48px;
}

#QuestionContent .top .right .tags .tag {
  margin: 0 10px 5px 0;
}

#QuestionContent .bottom .editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px auto;
}

#QuestionContent .bottom #myForm {
  display: flex;
  width: 320px;
  margin-left: 10px;
  align-items: center;
  align-self: flex-start; /* 添加这行 */
}

#QuestionContent .bottom #myForm label {
  display: block;
  width: 120px;
  margin: 0;
}

#QuestionContent .bottom #myForm select {
  width: 100%;
  padding: 5px;
}

#QuestionContent .bottom CodeEditor {
  width: 10vw;
  height: 50vh;
  padding: 20px;
  background-color: #40c057;
}

#QuestionContent .bottom .btn {
  position: absolute;
  bottom: 10px; /* 距离底部的距离 */
  right: 54px; /* 距离右侧的距离 */

  height: 46px;
  width: 128px;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 550;
  user-select: none;
}

#QuestionContent .bottom .btn:hover {
  color: #ffffff;
  /*background-color: rgba(0, 0, 0, 0.2); !* 使用半透明的背景颜色 *!*/
  /*44, 66, 90*/
  background-color: rgba(44, 66, 90, 0.4);
}
</style>
