<template>
  <div id="userLogin">
    <h2 style="color: #eee">注册</h2>
    <form @submit.prevent="handleRegister">
      <label>
        <input
          type="text"
          v-model="registerAccount"
          placeholder="account"
          required
          autocomplete="new-password"
          @input="checkAccount"
        />
        <span v-if="isRegisterAccountError" class="length-warning">X</span>
      </label>
      <label>
        <input
          type="password"
          v-model="registerPassword"
          placeholder="password"
          required
          autocomplete="new-password"
          @input="checkPasswordLength"
        />
        <span v-if="isRegisterPasswordError" class="length-warning">X</span>
      </label>
      <label>
        <input
          type="password"
          v-model="confirmPassword"
          placeholder="confirm password"
          required
          autocomplete="off"
          @input="checkConfirmPasswordLength"
        />
        <span v-if="isRegisterConfirmPasswordError" class="length-warning"
          >X</span
        >
      </label>
      <button type="submit">注册</button>
    </form>
  </div>
</template>

<style>
/* 保持样式与 LoginForm.vue 相同 */
#userLogin {
  display: flex;
  height: 500px;
  width: 400px;
  flex-direction: column;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

#userLogin h2 {
  font-size: 25px;
  margin-top: 60px;
  margin-bottom: 20px;
  user-select: none;
}

#userLogin form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
}

#userLogin form label input[type="text"],
#userLogin form label input[type="password"],
#userLogin form button {
  width: 250px;
  height: 50px;
  border-radius: 10px;
  margin: 15px auto;
}

#userLogin form label {
  color: #fff;
}

#userLogin form label input[type="text"],
#userLogin form label input[type="password"] {
  border: 1px solid #ccc;
  padding: 0 15px;
}

#userLogin form button {
  width: 200px;
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 20px;
  font-weight: 500;
  background-color: #fff;
  color: #050505;
  cursor: pointer;
}

label {
  position: relative; /* 设置 label 为相对定位，这样我们可以在它内部使用绝对定位 */
}

.length-warning {
  position: absolute; /* 使用绝对定位将元素定位在输入框的右侧 */
  right: 10px; /* 将元素向右移动 10px，使其与输入框的右边界对齐 */
  top: 50%; /* 将元素向上移动 50%，使其垂直居中 */
  transform: translateY(
    -50%
  ); /* 使用 transform 属性的 translateY 函数将元素向上移动自身高度的一半，以确保元素完全居中 */
  color: red;
  font-size: 20px;
  font-weight: 600;
}
</style>

<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { UserControllerService, UserRegisterRequest } from "../../../generated";
import message from "@arco-design/web-vue/es/message";

const registerAccount = ref("");
const registerPassword = ref("");
const confirmPassword = ref("");
const isRegisterAccountError = ref(false);
const isRegisterPasswordError = ref(false);
const isRegisterConfirmPasswordError = ref(false);
const accountPattern = /^[a-zA-Z][a-zA-Z0-9]{3,}$/;
const passwordPattern = /^(?=.*[0-9a-zA-Z])[0-9a-zA-Z]{8,}$/;

const router = useRouter();
const store = useStore();

const form = reactive({
  userAccount: "",
  userPassword: "",
  checkPassword: "",
} as UserRegisterRequest);

const checkAccount = () => {
  isRegisterAccountError.value =
    (registerAccount.value.length < 4 && registerAccount.value.length > 0) ||
    !accountPattern.test(registerAccount.value);
};

const checkPasswordLength = () => {
  isRegisterPasswordError.value =
    (registerPassword.value.length < 8 && registerPassword.value.length > 0) ||
    !passwordPattern.test(registerPassword.value);
};
const checkConfirmPasswordLength = () => {
  isRegisterConfirmPasswordError.value =
    (confirmPassword.value.length < 8 && confirmPassword.value.length > 0) ||
    !passwordPattern.test(confirmPassword.value) ||
    confirmPassword.value !== registerPassword.value;
};

const handleRegister = async () => {
  /**********************************************************************************/
  if (isRegisterAccountError.value) {
    message.error("账号只能是英文字母+数字的组合(首位字母)，且至少4位");
    return;
  }

  if (isRegisterPasswordError.value) {
    message.error("密码必须包含数字或英文字母，且至少8位");
    return;
  }

  if (isRegisterConfirmPasswordError.value) {
    message.error("两次输入的密码不一致");
    return;
  }
  /**********************************************************************************/

  form.userAccount = registerAccount.value;
  form.userPassword = registerPassword.value;
  form.checkPassword = confirmPassword.value;

  // alert("in handleSubmit" + JSON.stringify(form));
  const res = await UserControllerService.userRegisterUsingPost(form);
  // console.log(res);
  if (res.code === 0) {
    // alert("注册成功" + JSON.stringify(res.data));
    message.success("注册成功");
    // alert("test");
    location.reload();
    // router.push({
    //   path: "/user/login",
    //   replace: true,
    // });
  } else {
    // alert("注册失败");
    message.error("注册失败");
  }
};
</script>
