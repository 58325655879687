<template>
  <div class="right">
    <div class="notice">
      <img src="../../assets/ring0.png" style="height: 24px" />
    </div>
    <div class="user" @click="userLogin">
      <img src="../../assets/user0.png" style="height: 25px" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ACCESS_ENUM from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";
import { UserControllerService } from "../../../generated";

const noticeImgSrc = ref("../assets/ring0.svg");

const router = useRouter();
const store = useStore();
// const user = store.state.user;

const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
// const userRole = loginUser?.role;
// alert(userRole);

// 过滤掉隐藏的界面
const menu = computed(() => {
  return routes.filter((item, index) => {
    // 是否为隐藏页面
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 对提交记录页面特别关照，因为 routes 中的权限有点小bug
    if (item.path == "/question_submit") {
      return false;
    }
    // alert(item.meta?.hideWithNoLogin + " sdf " + userRole);
    // if (item.meta?.hideWithNoLogin == userRole) {
    //   return false;
    // }
    // 判断权限是否隐藏菜单
    if (!checkAccess(loginUser, item?.meta?.access as string)) {
      return false;
    }
    return true;
  });
});

// 路由跳转时，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 默认主页
const selectedKeys = ref(["/"]);

// 页面跳转事件
const doMenuClick = (key: string) => {
  router.push({
    path: key,
  });
};

// 登录
const userLogin = () => {
  // alert("userLogin");
  // todo 登陆的成功后，跳转到重定向页面
  router.push({
    path: "/user/login",
    replace: true,
  });
};
</script>

<style scoped>
.left,
.right {
  display: flex;
  align-items: center;
  height: 64px;
  /*background-color: mediumslateblue;*/
  color: white;
  font-size: 18px;
}

.left {
  width: auto;
  position: fixed;
  left: 10vw;
}

.left img {
  height: 56px;
  padding: 15px 0 15px 15px;
}

.custom-menu ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 12px;
  margin: 6px 6px 6px 0;
}

.custom-menu li {
  cursor: pointer;
  width: 100px;
  margin: 8px;
  padding: 4px;
  font-size: 20px;
  transition: border-bottom 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.custom-menu li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: white;
  transition: height 0.2s ease;
}

.custom-menu li:hover::after {
  height: 1px;
}

.custom-menu li ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.custom-menu li:hover ul {
  display: block;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 150px;
  position: fixed;
  right: 5vw;
  margin-top: 2px;
  margin-right: 10vw;
}

.right .notice,
.right .user {
  position: static;
}

.right .user img,
.right .notice img {
  height: 36px;
  padding: 13px;
  cursor: pointer;
  filter: brightness(0.8); /* 降低图片的亮度 */
  transition: filter 0.3s ease;
}

.right .user img:hover,
.right .notice img:hover {
  filter: brightness(2); /* 在鼠标悬停时恢复图片的亮度 */
}

.right .notice {
  margin-top: 3px;
}
</style>
