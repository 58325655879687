// initial state
import { StoreOptions } from "vuex";
import { UserControllerService } from "../../generated";
import axios from "axios"; // 导入 axios
import ACCESS_ENUM from "@/access/accessEnum";
import store from "@/store/index";

// getters：便捷获取变量的一些方法

export default {
  namespaced: true,
  state: () => ({
    loginUser: {
      userRole: ACCESS_ENUM.NOT_LOGIN,
    },
  }),

  // actions:
  actions: {
    async getLoginUser({ commit, state }, payload) {
      // alert("before getLoginUser: " + JSON.stringify(payload));
      // 从远程请求获取登录信息
      const res = await UserControllerService.getLoginUserUsingGet();
      // console.log("getLoginUser_res: " + res.data);
      // alert("after getLoginUser_res: " + JSON.stringify(res.data));

      if (res && res.code === 0) {
        // alert("in if_res, getLoginUser_res: " + JSON.stringify(res.data));
        commit("updateUser", res.data);
        // 在用户登录成功后，将登录信息保存到 localStorage
      } else {
        commit("updateUser", {
          ...state.loginUser,
          userRole: ACCESS_ENUM.NOT_LOGIN,
        });
      }
      // commit("updateUser", payload);
    },

    async userLogout({ commit, state }) {
      // alert("userLogout: " + JSON.stringify(commit));
      // 发送退出登录请求给后端
      axios
        .post("/logout")
        .then((response) => {
          // 清除前端存储的用户登录状态
          commit("updateUser", {
            ...state.loginUser,
            userRole: ACCESS_ENUM.NOT_LOGIN,
          });
        })
        .catch((error) => {
          console.error("Logout failed: ", error);
        });
    },
  },

  // mutations：crud
  mutations: {
    updateUser(state, payload) {
      // alert("updateUser: " + JSON.stringify(payload));
      state.loginUser = payload;
    },
  },
} as StoreOptions<any>;
