<template>
  <div id="userLayout" class="layout">
    <a-layout style="height: 100vh; width: 100vw">
      <a-layout-header class="header">
        <GlobalHeader></GlobalHeader>
      </a-layout-header>

      <a-layout-content class="content custom-layout-content">
        <router-view class="content_router"></router-view>
      </a-layout-content>

      <a-layout-footer>
        <div class="footer" style="font-size: 16px">
          <a href="https://gitee.com/xiaozz29">
            <img src="../assets/gitee.svg" style="height: 20px" />
            <div class="xiaozz" style="font-size: 20px">xiaozz</div>
          </a>
        </div>
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<style scoped>
#userLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  align-items: center;
  /*text-align: center;*/
  margin: 0;
  padding: 0;
  background: url("../../public/wallpaper2.jpg");
  /*background: url("../../public/wallpaper2.jpg");*/
  background-size: cover;
  /*overflow: auto;*/
  overflow: scroll;
  overflow-x: hidden; /* 添加这一行 */
  /*background-attachment: fixed; !* 背景图片固定不动 *!*/
}

.layout :deep(.arco-layout-header),
.layout :deep(.arco-layout-footer),
.layout :deep(.arco-layout-content) {
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  font-size: 16px;
  font-stretch: condensed;
  /*text-align: center;*/
}

.layout :deep(.arco-layout-header) {
  /*margin-top: 30px;*/
  height: 70px;
  width: 100vw;
  padding-bottom: 50px;
  /*background-color: var(--color-primary-light-4);*/
  text-align: center;
  position: fixed; /* 将 header 固定在页面顶部 */
  top: 0; /* 距离页面顶部为 0 */
  left: 0; /* 距离页面左侧为 0 */
  z-index: 999;
  /* 其他样式保持不变 */

  background: url("../../public/wallpaper2.jpg");
  background-size: cover;
  overflow: auto;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.layout :deep(.arco-layout-content) {
  display: flex;
  /*justify-content: center;*/
  width: 1200px;
  margin: 100px auto 20px auto;
  padding: 30px 0 10px 0;
  /*flex-grow: 1;*/
  overflow: visible;
  /*overflow-y: overlay;*/

  backdrop-filter: blur(10px); /* 添加模糊效果 */
  background-color: rgba(255, 255, 255, 0.6); /* 使用半透明的背景颜色 */
  border-radius: 10px;
}

.layout :deep(.arco-layout-footer) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  /*background-color: var(--color-primary-light-4);*/
  /*background-color: #42b983;*/
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.2);
  width: 100vw; /* 设置 footer 宽度与页面宽度一致 */
  text-align: center;

  position: static;
  bottom: 0; /* 距离页面底部为 0 */
  left: 0; /* 距离页面左侧为 0 */
  z-index: 999; /* 设置 footer 的层级，使其位于其他内容之上 */
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  /*justify-content: space-around; !* 水平分布子元素 *!*/
}

.footer:hover {
  cursor: pointer;
}

.footer a {
  width: auto;
  text-decoration: none; /* 取消下划线 */
  color: inherit; /* 使用父元素的字体颜色 */
  justify-content: space-around; /* 水平分布子元素 */

  display: flex;
  align-items: center; /* 垂直居中 */
}

.footer img {
  height: 16px;
  margin-right: 8px;
}

.footer .xiaozz {
  display: block;
  cursor: pointer;
}
</style>
<script setup lang="ts">
import { useStore } from "vuex";
import GlobalHeader from "@/components/Header/GlobalHeader.vue";

const store = useStore();
// const user = store.state.user;
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
</script>
