<template>
  <div id="DoQuestionView">
    <div class="questionTitle">
      <p v-if="question">{{ question.id + ". " + question.title }}</p>
      <!--      <p v-if="question">{{ question.title }}</p>-->
      <p v-else>加载中...</p>
    </div>

    <div class="questionTabs">
      <ul>
        <li
          role="presentation"
          :class="activeTab === 'content' ? 'active' : 'inactive'"
          @click="activeTab = 'content'"
        >
          <a class="problem-content-sub-btn">
            <span class="glyphicon glyphicon-list-alt"></span>
            &nbsp;题目
          </a>
        </li>
        <li
          role="presentation"
          :class="activeTab === 'submission' ? 'active' : 'inactive'"
          @click="activeTab = 'submission'"
        >
          <a class="problem-content-sub-btn">
            <span class="glyphicon glyphicon-list"></span>
            &nbsp;提交记录
          </a>
        </li>
        <li
          role="presentation"
          :class="activeTab === 'solution' ? 'active' : 'inactive'"
          @click="activeTab = 'solution'"
        >
          <a class="problem-content-sub-btn">
            <span class="glyphicon glyphicon-book"></span>
            &nbsp;题解
          </a>
        </li>
      </ul>
    </div>

    <br />

    <!-- 根据 activeTab 的值显示不同的内容 -->
    <div v-if="activeTab === 'content'">
      <!-- 题目内容 -->
      <!-- 在 QuestionContent 组件上监听 updateActiveTab 事件 -->
      <QuestionContent
        :question="question"
        :code="loadCodeFromLocal()"
        @updateActiveTab="activeTab = $event"
      ></QuestionContent>
    </div>

    <div v-if="activeTab === 'submission'">
      <!-- 提交记录内容 -->
      <!--      <p>这里是提交记录</p>-->
      <QuestionSubmission :questionId="props.id"></QuestionSubmission>
    </div>

    <div v-if="activeTab === 'solution'">
      <!-- 题解内容 -->
      <!--      <p>这里是题解内容</p>-->
      <QuestionSolution :question="question"></QuestionSolution>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, withDefaults } from "vue";
import { QuestionControllerService, QuestionVO } from "../../../generated";
import message from "@arco-design/web-vue/es/message";
import { RouteLocationNormalized, useRouter } from "vue-router";
import store from "@/store";
import QuestionContent from "@/components/Question/QuestionContent.vue";
import QuestionSolution from "@/components/Question/QuestionSolution.vue";
import QuestionSubmission from "@/components/Question/QuestionSubmission.vue";

// 定义数据
const activeTab = ref("content"); // 默认激活的 tab

const router = useRouter();

interface Props {
  id: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: () => "",
});

const question = ref<QuestionVO>();

const loadData = async () => {
  const res = await QuestionControllerService.getQuestionVoByIdUsingGet(
    props.id as any
  );
  if (res.code === 0) {
    // alert(JSON.stringify(res.data));
    question.value = res.data;
  } else {
    message.error("加载失败，" + res.message);
  }
};

// 加载本地缓存中的代码
const loadCodeFromLocal = () => {
  const loginUserString = localStorage.getItem("loginUser");
  const loginUser = JSON.parse(loginUserString || "");
  const userId = loginUser.id; // 获取当前登录用户的ID
  // alert(userId);
  // const questionId = props.question.id || null; // 获取题目ID
  const questionId = props.id ? props.id : null;
  // const questionId = 0; // 获取题目ID
  // alert("InDoQuestionView");
  if (userId && questionId) {
    const key = `${userId}_${questionId}_code`; // 组合键名
    const code = localStorage.getItem(key); // 从本地缓存中获取代码
    // alert("In DoQuestionView: " + code);
    if (code) {
      return code;
    }
  }
  return "";
};

/**
 * 页面加载时，请求数据
 */
onMounted(async () => {
  // 在页面加载时读取 localStorage 里的状态信息
  const loginUserString = localStorage.getItem("loginUser");
  if (loginUserString) {
    const loginUser = JSON.parse(loginUserString);
    if (loginUser) {
      // 如果存在登录信息，更新到 Vuex 的状态中
      store.commit("updateUser", loginUser);
    }
  }

  // 加载数据
  await loadData();

  // 设置页面标题为问题的标题
  if (question.value) {
    document.title = `${question.value.id}. ${question.value.title} - EzCoding`;
  }
});
</script>

<style>
#DoQuestionView {
  max-width: 1400px;
  margin: 0 20px;
}

#viewQuestionView .arco-space-horizontal .arco-space-item {
  margin-bottom: 0 !important;
}

#DoQuestionView .questionTitle p {
  margin: 40px 0 30px 24px;
  font-size: 32px;
  font-weight: bold;
}

.questionTabs {
  margin: 0;
  padding: 0;
  position: relative; /* 添加相对定位 */
}

.questionTabs ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  border-bottom: 2px solid #eee;
  position: relative; /* 添加相对定位 */
}

.questionTabs li {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 36px;
  width: 120px;
  padding: 6px;
  margin: 0 4px 0 4px;
  font-size: 20px;
  position: relative;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  user-select: none;
}

.questionTabs li:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.1); /* 使用半透明的背景颜色 */
}

.questionTabs .active {
  /*color: #284c66;*/
  color: black;
  font-weight: 500;
  /*background-color: #eee;*/
  background-color: rgba(255, 255, 255, 0.7); /* 使用半透明的背景颜色 */
  pointer-events: none;
}

.questionTabs .inactive {
  /*border: 2px solid transparent;*/
}
</style>
