import ACCESS_ENUM from "@/access/accessEnum";

/**
 * 权限校验（判断当前登录用户，是否具有某个权限）
 * @param loginUser
 * @param needAccess
 * @return boolean 有无权限
 */
const checkAccess = (loginUser: any, needAccess = ACCESS_ENUM.NOT_LOGIN) => {
  // 获取当前登录用户的权限
  const loginUserAccess = loginUser?.userRole ?? ACCESS_ENUM.NOT_LOGIN;
  // alert(loginUserAccess);

  // 1. 不需要登录
  if (needAccess === ACCESS_ENUM.NOT_LOGIN) {
    return true;
  }
  // 2. 需要登录 => 检验是否登录
  if (needAccess === ACCESS_ENUM.USER) {
    if (loginUserAccess === ACCESS_ENUM.NOT_LOGIN) {
      return false;
    }
  }
  // 3. 需要管理员 => 检验是否为管理员
  if (needAccess === ACCESS_ENUM.ADMIN) {
    if (loginUserAccess !== ACCESS_ENUM.ADMIN) {
      return false;
    }
  }

  return true;
};

export default checkAccess;
