<template>
  <div class="custom-menu">
    <ul class="main-ul">
      <li
        class="main-li"
        v-for="item in menu"
        :key="item.path"
        @mousedown="doMenuClick($event, item.path)"
      >
        {{ item.name }}
      </li>

      <li
        class="main-li"
        @mouseenter="showQuestionMenu = true"
        @mouseleave="showQuestionMenu = false"
        v-if="loginUser.userRole === 'admin'"
      >
        管理题目
        <ul class="minor-ul" v-show="showQuestionMenu">
          <li
            class="minor-li"
            v-for="subItem in questionMenu"
            :key="subItem.path"
            @mousedown="doMenuClick($event, subItem.path)"
          >
            {{ subItem.name }}
          </li>
        </ul>
      </li>

      <li
        class="main-li"
        @mouseenter="showQuestionLessonMenu = true"
        @mouseleave="showQuestionLessonMenu = false"
        v-if="loginUser.userRole === 'admin'"
      >
        管理题单
        <ul class="minor-ul" v-show="showQuestionLessonMenu">
          <li
            class="minor-li"
            v-for="subItem in questionLessonMenu"
            :key="subItem.path"
            @mousedown="doMenuClick($event, subItem.path)"
          >
            {{ subItem.name }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { routes } from "@/router/routes";
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import checkAccess from "@/access/checkAccess";

const router = useRouter();
const store = useStore();
const loginUser = JSON.parse(localStorage.getItem("loginUser") || "{}");
// alert(JSON.stringify(loginUser));

// 过滤掉隐藏的界面
const menu = computed(() => {
  return routes.filter((item, index) => {
    // 是否为隐藏页面
    if (item.meta?.hideInMenu) {
      return false;
    }
    // 如果是‘提交记录’页面，且未登录，则隐藏
    // 对提交记录页面特别关照，因为 routes 中的权限有点小bug
    if (item.path == "/question_submit" && !loginUser?.userRole) {
      return false;
    }
    // 判断权限是否隐藏菜单
    if (!checkAccess(loginUser, item?.meta?.access as string)) {
      return false;
    }
    return true;
  });
});

// 过滤出管理题目的子菜单项
const questionMenu = computed(() => {
  return routes.filter((item) => {
    return item.path === "/add/question" || item.path === "/manage/question";
  });
});

const questionLessonMenu = computed(() => {
  return routes.filter((item) => {
    return (
      item.path === "/add/question_lesson" ||
      item.path === "/manage/question_lesson"
    );
  });
});

// 路由跳转时，更新选中的菜单项
router.afterEach((to, from, failure) => {
  selectedKeys.value = [to.path];
});

// 默认主页
const selectedKeys = ref(["/"]);

// 控制子菜单显示
const showQuestionMenu = ref(false);
const showQuestionLessonMenu = ref(false);

// 页面跳转事件
const doMenuClick = (event: MouseEvent, path: string) => {
  event.preventDefault();

  if (event.button === 0) {
    // 左键点击，使用 router.push 在当前标签页跳转
    router.push({
      path: path,
    });
  } else if (event.button === 1) {
    // 中键点击，使用 window.open 在新标签页打开链接
    window.open(window.location.origin + router.resolve(path).href, "_blank");
  }
};
</script>

<style scoped>
.custom-menu .main-ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 12px;
  margin: 6px 6px 6px 0;
  user-select: none; /* 文字不可被选中 */
}

.custom-menu .main-li {
  cursor: pointer;
  width: 100px;
  margin: 8px;
  padding: 4px;
  font-size: 20px;
  transition: border-bottom 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.custom-menu .main-li::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background: white;
  transition: height 0.2s ease;
}

.custom-menu .main-li:hover::after {
  height: 1px;
}

.custom-menu .main-li .minor-ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.custom-menu .main-li:hover .minor-ul {
  display: block;
}

.custom-menu .main-li .minor-ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*background-color: white;*/
  font-size: 18px;
  color: #fff;

  backdrop-filter: blur(30px);
  background-color: rgba(200, 200, 200, 0.4);
  border-bottom-left-radius: 5px; /* 只影响左下角 */
  border-bottom-right-radius: 5px; /* 只影响右下角 */
}

.custom-menu .main-li .minor-ul .minor-li {
  margin: 0;
  padding: 10px 18px;
  border-radius: 5px;
}

.custom-menu .main-li .minor-ul .minor-li:hover {
  color: #050505;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
